<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-helps-list')"
        @save="createItem"
        :title="this.currentItem.title_german"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(createItem)" @reset.prevent="resetForm">
        <b-card>
            <div class="mb-2">
            <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>
          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-2">
              <b-form-group :label="$t('Platform')">
                <b-form-select v-model="currentItem.platform" :options="platform">
                </b-form-select>

                  <small v-if="validate && Object.keys(validation).includes('platform')" class="text-danger"
                  >{{ $t(validation.platform[0]) }}</small>
                </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1 mt-2">
                <b-form-group :label="$t('Slug')">
                    <b-form-input v-model="currentItem.slug" id="slug" :placeholder="$t('Slug')"/>
                  <small v-if="validate && Object.keys(validation).includes('slug')" class="text-danger"
                  >{{ $t(validation.slug[0]) }}</small>
                </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" xl="12" class="mb-1 mt-2">
              <b-form-group :label="$t('Title German')">
               <b-form-input v-model="currentItem.title_german" id="title_german" :placeholder="$t('Title German')"/>
                  <small v-if="validate && Object.keys(validation).includes('title_german')" class="text-danger"
                  >{{ $t(validation.title_german[0]) }}</small>
                </b-form-group>
            </b-col>

            <b-col md="12" xl="12" class="mb-1 mt-2">
                <b-form-group :label="$t('Content German')">
                  <quill-editor v-model="currentItem.content_german" :options="snowOption"/>
                  <small v-if="validate && Object.keys(validation).includes('content_german')" class="text-danger"
                  >{{ $t(validation.content_german[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" xl="12" class="mb-1 mt-2">
              <b-form-group :label="$t('Title English')">
               <b-form-input v-model="currentItem.title_english" id="title_english" :placeholder="$t('Title English')"/>
                </b-form-group>
            </b-col>

            <b-col md="12" xl="12" class="mb-1 mt-2">
                <b-form-group :label="$t('Content English')">
                  <quill-editor v-model="currentItem.content_english" :options="snowOption"/>
                </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
            {{ $t('Add help') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { required, alphaNum, email } from '@validations'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { BCardActions } from '@core/components/b-card-actions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    Cleave,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
        snowOption: {
          theme: 'snow',
        },
      currentItem: {
        id: '',
        status: 1,
        platform:null,
        slug:'',
        title_german:'',
        content_german:'',
        title_english:'',
        content_english:'',
      },
      validation: {},
      validate: false,
      loaded: true,
    }
  },
  computed:{
    platform(){
      return [
        { value: null, text: this.$i18n.t('Please select a platform') },
        {
          value: 'Admin',
          text: this.$i18n.t('Admin'),
        },
        {
          value: 'Expert',
          text: this.$i18n.t('Expert'),
        },
        {
          value: 'Client',
          text: this.$i18n.t('Client'),
        },
        {
          value: 'Affiliate',
          text: this.$i18n.t('Affiliate'),
        },
      ]
    },
  },
  methods: {
    createItem() {
      let url = `/helpareas`
      this.loaded = false
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperAddItem(`/helpareas`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-helps-edit',
                      params: {
                        helpsId: response.data.id
                      },
                    },
                    () => setTimeout(() => {
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            }

            this.loaded = true
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {
  },
}
</script>

<style>
.ql-container.ql-snow {
    min-height: 150px;
}
</style>
